$('.js-home-slider').slick({
    autoplay: true,
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
});

if ($('.hp').length) {
    if ($(window).width() < 640) {
        $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
        $('.js-footer-social').insertAfter('.support-list');
    }

    var moveHTML = debounce(function () {
        if ($(window).width() < 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
            $('.js-footer-social').insertAfter('.support-list');
        }
        if ($(window).width() > 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__header');
            $('.js-footer-social').insertAfter('.footer__about');
        }
    }, 500);

    window.addEventListener('resize', moveHTML);
}


// animate when scroll
// Check if element is scrolled into view
if ( $('.scroll-animations').length ) {
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
  // If element is scrolled into view, fade it in
  $(window).scroll(function() {
    if ( $(window).width() > 639 ) {
      console.log('Front Page animation activated');
      $('.scroll-animations .animated').each(function() {
        if (isScrolledIntoView(this) === true) {
          if ($(this).hasClass('cover-loc-section')) {
            $(this).addClass('fadeInRight');
          } else if ($(this).hasClass('finishes-section')){
            $(this).addClass('fadeIn');
          } else {
            $(this).addClass('fadeInLeft');
          }
        }
      });
    } else {
      console.log('Front Page animation removed from mobile');
    }
  });
}


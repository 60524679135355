function moveElement(detachingEle, attachingEle) {
  let img = $('.' + detachingEle).detach();
  $('.' + attachingEle).append(img);
}

let elementToMove = 'js-move-finishes';
let desktopAnchor = 'wheel-detail-stage';
let mobileAnchor = 'wheel-detail-info';

if ($('.' + elementToMove).length) {
  if (Foundation.MediaQuery.current === 'small') {
    moveElement(elementToMove, mobileAnchor);
  }
  $('.' + elementToMove).removeClass('hide');

  $(window).on('changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.atLeast('medium')) {
      moveElement(elementToMove, desktopAnchor);
    } else {
      moveElement(elementToMove, mobileAnchor);
    }
  });
}

$(window).load(function(){
  if ($('#wheel').length && $(window).width() > 639) {
    var topRight = $('.detail-top .right');
    var topLeft = $('.detail-top .left');
    var rightHeight = topRight.height();
    var leftHeight = topLeft.height();
    if (rightHeight > leftHeight) {
      topLeft.height(rightHeight);
    } else {
      topRight.height(leftHeight);
    }
    
    var techRight = $('.details-tech-specs .specs-caps');
    var techLeft = $('.details-tech-specs .specs-information');
    var techRightHeight = techRight.height();
    var techLeftHeight = techLeft.height();
    console.log(techLeft);
    console.log(techLeftHeight);
    console.log(techRight);
    console.log(techRightHeight);
    if ( techRightHeight > techLeftHeight ) {
      techLeft.height(techRightHeight);
    } else {
      techRight.height(techLeftHeight);
    }
  }
});


// stage and angles
$('.main-image')
  .magnificPopup({
    delegate: 'a',
    type: 'image',
    mainClass: 'popup-bkg tester',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1],
      dots: false,
    },
  }).slick({
    arrows: false,
    asNavFor: '.details-angles,.alt-images',
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });

$('.details-angles,.alt-images').slick({
  arrows: false,
  asNavFor: '.main-image',
  dots: false,
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [{
      breakpoint: 600,
      settings: {
        /*dots: true,*/
        slidesToScroll: 4,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 900,
      settings: {
        /*dots: true,*/
        slidesToScroll: 6,
        slidesToShow: 6
      }
    }
  ]
});


// angles 
/*$('.details-angles').magnificPopup({
  delegate:'a',
  type:'image',
  mainClass: 'wheel-angles-popup',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1],
    dots: false,
  },
});*/


// wheel gallery
$('.details-wheel-gallery')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      mainClass: 'wheel-gallery-popup',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false,
      },
    });
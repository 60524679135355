/* global $, Foundation, MotionUI, List */
/* ==========================================================================
   Table of Contents
   ========================================================================== */

/*
    1.   General
    ---  Product Pages  ---
    2.1  Brand Pages
    2.2  Detail Pages
    ---  Gallery Pages  ---
    3.1  Gallery Landing Page
    3.3  Gallery Detail Page
    ---  Other Pages  ---
    4.1  Home Page
    4.2  Contact Page
    4.3  Article Page
*/
/* ==========================================================================
1. General
========================================================================== */
$(document).foundation();

var $img = $('.parallax .img');
var $heading = $('.parallax h1');
var scroller;
var wh = $(window).height();

$('#fitmentSubmit').on('click', function submitFitment() {
  var size = $('#size');
  if (size.val() && size.val() !== 'Select Size') {
    size.change();
  }
});

// Mobile Accordion
if ($('.accordion').length && Foundation.MediaQuery.atLeast('medium')) {
  $('.accordion').each(function openAccordion() {
    $(this).foundation(
      'down',
      $(this)
      .find('.accordion-content')
      .first(),
      true
    );
  });
}

// Scroll to section
$('.scroll-to').on('click', function scrollToElem(event) {
  var headerHeight = $('header').height();
  var target = $($(this).attr('href'));
  var scrollTarget = target.offset().top - headerHeight;
  if (target.length) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: scrollTarget,
      },
      1000
    );
  }
});

// Varify captcha
$('.rfqSubmit, .check-captcha').on('click', function () {
  if (grecaptcha.getResponse() === '') {
    var response = document.getElementById('check-captcha');
    response.innerHTML = 'Captcha box not selected';
    return false;
  } else {
    return true;
  }
});

//Show/Hide navigation search box form
var searchBoxFlag = {
  active: false,
};

$(document).click(function (e) {
  closeSearchBox(e);
});

function closeSearchBox(e) {
  var target = $('.nav-search');

  if (!searchBoxFlag.active) {
    return;
  }

  if (!target.is(e.target) && target.has(e.target).length === 0) {
    $('.js-search-box').slideUp();
    $('.js-overlay-nav').addClass('hidden');
    searchBoxFlag.active = false;
  }
}

// search menu link slide toggle
$('.js-search-link').on('click', function (e) {
  e.preventDefault();
  $('.js-search-box').slideToggle();
  $('.js-overlay-nav').toggleClass('hidden');
  searchBoxFlag.active = !searchBoxFlag.active;
});

$('.js-search-btn').magnificPopup({
  items: {
    src: '<div class="white text-center processing">' +
      '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
      '</div>',
    type: 'inline',
    modal: true,
  },
  callbacks: {
    open: function () {
      $('#search-form').submit();
    },
  },
});

///// header sizing /////
$(window).load(function(){
  if($(window).width() > 1023) {
    var headerMenu = $('#responsive-menu');
    var headerLogo = $('nav');
    var headerMenuHeight = $('#responsive-menu').height();
    var headerLogoHeight = $('nav').height();
    
    if (headerMenuHeight > headerLogoHeight) {
      headerLogo.height(headerMenuHeight);
    } else {
      headerMenu.height(headerLogoHeight);
    }
  } else {
    console.log('mobile mode activated');
  }
});


/* ==========================================================================
     2.1 Brand Pages
     ========================================================================== */

// Function for Wheel Filter to change page to 1 when a brand is selected
// on a page greater than 1
function replaceUrlParam(url, key, value) {
  if (value === null) value = '';
  var pattern = new RegExp('\\b(' + key + '=).*?(&|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + value + '$2');
  }
  return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
}

// Wheel filters
$('.js-finish-filter, .js-brand-filter, #tireFilters').on('change', 'select', function (event) {
  event.preventDefault();
  // Get type of filter and current query
  var $this = $(this);
  var type = $this.attr('name');
  var query = window.location.search;
  var urlSection = '';
  // Maintain BrandID and BrandName
  var brandID; // Use this method if still using web.config to rename url string to friendly urls
  var title = $this.find('option:selected').text();
  title = title.replace(/\s+/g, '-').toLowerCase();

  var brandTitle;
  var wheelMatch = window.location.href.match('wheels/' + /\d+/) > -1;

  // If this filter has already been applied, replace
  if (query.indexOf(type + '=') > -1) {
    var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
    query = query.replace(regex, '$1' + $this.val());
    // If there are already other params
  } else if (query.indexOf('?') > -1) {
    if (type == 'brand' && wheelMatch) {
      // set brandID variable
      brandID = $this.val();
      brandTitle = title;
    } else {
      query += '&' + type + '=' + $this.val();
    }
    // If this is the only param
  } else {
    if (type == 'brand' && wheelMatch) {
      // set brandID variable
      brandID = $this.val();
      brandTitle = title;
    } else {
      query += '?' + type + '=' + $this.val();
    }
  }
  // Set the page to 1
  if (window.location.href.match('page')) {
    query = replaceUrlParam(query, 'page', 1);
  }
  if ($this.parent().attr('id') == 'tireFilters') {
    if (window.location.href.match('tires/' + /\d+/) > -1) {}
  } else if (window.location.href.match('wheels/' + /\d+/) > -1) {
    var urlSplit = document.location.href.split('/');
    if (typeof brandID !== 'undefined') {
      // replaces the brandID in url string
      urlSection = brandID;
    } else {
      urlSection = urlSplit[4];
      brandTitle = urlSplit[5];
    }
    if (urlSection > 0) {
      window.location.href = '/wheels/' + urlSection + '/' + brandTitle + '/' + query;
    } else {
      window.location.href = '/wheels/' + query;
    }
  } else {
    window.location.href = window.location.pathname + query;
  }
});

// PARALLAX EFFECT   //codepen.io/sallar/pen/lobfp
// requestAnimationFrame Shim
window.requestAnimFrame = (function animFramPoly() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function animTimout(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

// Scroller
function Scroller() {
  this.latestKnownScrollY = 0;
  this.ticking = false;
}

Scroller.prototype = {
  // Initialize
  init: function init() {
    window.addEventListener('scroll', this.onScroll.bind(this), false);
  },

  // Capture Scroll
  onScroll: function onScroll() {
    this.latestKnownScrollY = window.scrollY;
    this.requestTick();
  },

  // Request a Tick
  requestTick: function requestTick() {
    if (!this.ticking) {
      window.requestAnimFrame(this.update.bind(this));
    }
    this.ticking = true;
  },

  // Update
  update: function update() {
    var currentScrollY = this.latestKnownScrollY;

    // Do The Dirty Work Here
    var imgScroll = currentScrollY / 2;
    var headScroll = currentScrollY / 3;

    this.ticking = false;

    $img.css({
      transform: 'translateY(' + imgScroll + 'px)',
      '-moz-transform': 'translateY(' + imgScroll + 'px)',
      '-webkit-transform': 'translateY(' + imgScroll + 'px)',
    });

    $heading.css({
      transform: 'translateY(' + headScroll + 'px)',
      '-moz-transform': 'translateY(' + headScroll + 'px)',
      '-webkit-transform': 'translateY(' + headScroll + 'px)',
    });
  },
};

// Attach!
if ($('.parallax .img').length || $('.parallax h1').length) {
  scroller = new Scroller();
  scroller.init();
}

/* ==========================================================================
     WHEEL FILTERS
     ========================================================================== */
if ($('.wheel-filter').length) {
  // Disable 'width' when no diameter is available
  $('#wheel-diam').on('change', function () {
    if ($(this).val() !== '') {
      $('#wheel-width').prop('disabled', false);
    } else {
      $('#wheel-width').prop('disabled', true);
    }
  });

  $('.wheel-filter select').on('change', function () {
    $('.js-show-search').removeClass('hidden');
  });

  $('.wheel-filter').on('submit', function (event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: '<div class="white text-center processing">' +
          '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
          '</div>',
      },
      closeBtnInside: false,
    });

    var filterURL = $('.wheel-filter :input')
      .filter(function (index, element) {
        return $(element).val() !== '';
      })
      .serialize();

    window.location.href = '/wheels/?' + filterURL;
  });
}

/* ==========================================================================
     2.2 Detail Pages
     ========================================================================== */
// Slider and popup for main image
/*
function initializeMainImage() {
  $('.main-image')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      mainClass: 'popup-bkg tester',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false,
      },
    }).slick({
      arrows: false,
      asNavFor: '.alt-images',
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

  $('.alt-images').slick({
    arrows: false,
    asNavFor: '.main-image',
    dots: false,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 20,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToScroll: 4,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          dots: true,
          slidesToScroll: 6,
          slidesToShow: 6
        }
      }
    ]
  });
}

initializeMainImage();
*/

// Gallery View Popup
$('.galleryView-open').on('click', function () {
  for (var dataName in $(this).data()) {
    if ($(this).data()[dataName] === '') {
      continue;
    }

    if (dataName === 'wheelimage') {
      $('#galleryView-popup img').attr('src', $(this).data()[dataName]);
    }
    
    var htmlString = '';
    if (dataName === 'link') {
      htmlString = '<a href="' + $(this).data()[dataName] + '">View Gallery</a>';
      console.log(htmlString);
    } else {
      htmlString = dataName;
      htmlString += ': ';
      htmlString += $(this).data()[dataName];
      htmlString = htmlString.replace(/car: /, '').replace(/size/, ' size');
    }
    $('.galleryView-info')
      .find('.popup-' + dataName)
      .html(htmlString);
  }
  $.magnificPopup.open({
    items: {
      src: '#galleryView-popup',
      type: 'inline',
    },
  });
});

// Show/Hide excess paragraph lines
function getTextHeight(selector) {
  var height = parseFloat($(selector).height());
  var lineHeight = parseFloat($(selector).css('line-height'));
  return Math.round(height / lineHeight);
}

// Show/Hide excess information
if ($('.info--limit').length) {
  if (getTextHeight('.info--limit') > 5) {
    $('.info--limit').addClass('info--height');
    $('.info-show').removeClass('hidden');
  }
}

if ($('.wheel-galleryList').length) {
  if ($('.wheel-galleryList__item').length > 6) {
    $('.wg-Show').removeClass('hidden');
  }
}

function toggleButtons(classSelect, cssSelect, toggleShowBtn, toggleHideBtn) {
  $(classSelect).toggleClass(cssSelect);
  $(toggleShowBtn).toggleClass('hidden');
  $(toggleHideBtn).toggleClass('hidden');
}

$('.info-show, .info-hide').on('click', function toggleParagraph() {
  toggleButtons('.info--limit', 'info--height', '.info-show', '.info-hide');
});
$('.wg-Show, .wg-Hide').on('click', function toggleGallery() {
  toggleButtons(
    '.wheel-galleryList__item',
    'wheel-galleryList__item--display',
    '.wg-Show',
    '.wg-Hide'
  );
});

function toggleBoltPatStringSize(type, event) {
  var bpList = $('.js-bpStringLength').data('list');
  var bpArray = bpList.split(',');

  if (event === undefined) {
    return;
  }

  if (type !== 'more' && bpArray.length > 5) {
    bpList = bpArray.slice(0, 5);
  }
  if (event.type === 'click') {
    $('.js-bpStringLength')
      .siblings('.js-show-less')
      .toggleClass('hidden');
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .toggleClass('hidden');
  } else {
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .removeClass('hidden');
  }

  $('.js-bpStringLength').text('Custom Drilled ' + bpList);
}

if ($('.js-bpStringLength').length) {
  toggleBoltPatStringSize('default', '');
  $('.js-show-more').on('click', function () {
    toggleBoltPatStringSize('more', event);
  });
  $('.js-show-less').on('click', function () {
    toggleBoltPatStringSize('less', event);
  });
}

// Get see on vehicle image
function detailCarImage(car, year, make, model, submodel, wheel, color, body, configid, option) {
  try {
    $.getJSON(
      '//ver1.iconfigurators.app/ap-json/ap-image-ID-color.aspx' +
      `?year=${year}` +
      `&make=${make}` +
      `&model=${model}` +
      `&submodel=${submodel}` +
      `&wheel=${wheel}` +
      `&color=${color}` +
      `&body=${body}` +
      `&configid=${configid}` +
      `&option=${option}`,
      function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append(
                `<option value="${colorIds[index]}" selected> ${colorNames[index]} </option>`
              );
            } else {
              $('#vehicle-colors').append(
                '<option value="' + colorIds[index] + '">' + colorNames[index] + '</option>'
              );
            }
          }

          // Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append(
                '<option data-id="' +
                j +
                '" value ="' +
                bodyNames[j] +
                '" selected>' +
                bodyNames[j] +
                '</option>'
              );
            } else {
              $('#vehicle-body').append(
                '<option data-id="' +
                j +
                '" value ="' +
                bodyNames[j] +
                '" >' +
                bodyNames[j] +
                '</option>'
              );
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(i, a) {
            return a.replace(
              /(&body=)([0-9]+)?(&color=)([0-9]+)?/gi,
              '$1' +
              $('#vehicle-body')
              .find(':selected')
              .data('id') +
              '$3' +
              $('#vehicle-colors').val()
            );
          });
        } else {
          car
            .find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
            .text(
              'We do not currently have an image of this wheel on your vehicle. Check back soon.'
            );
        }
      }
    );
  } catch (err) {
    car
      .find('.img-wrapper')
      .addClass('no-fitment')
      .find('.error')
      .text('Error Loading Image for Your Vehicle');
  }
}

// Set Dropdowns
function setDropdownHandlers(car, year, make, model, submodel, configid, option) {
  // Bind the color select
  $('#vehicle-colors').change(function changeColor() {
    var body = $('#vehicle-body').val();
    var color = $('#vehicle-colors').val();
    var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
    $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
      return a.replace(/(&color=)([0-9]+)?/gi, '$1' + color);
    });
    detailCarImage(car, year, make, model, submodel, wheel, color, body, configid, option);
  });
  // Bind the body select
  $('#vehicle-body').change(function changeBody() {
    var body = $('#vehicle-body').val();
    var color = 0;
    var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
    $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
      return a.replace(
        /(&body=)([0-9]+)?(&color=)([0-9]+)?/gi,
        '$1' +
        $('#vehicle-body')
        .find(':selected')
        .data('id') +
        '$3'
      );
    });
    detailCarImage(car, year, make, model, submodel, wheel, color, body, configid, option);
  });
}

// Check if vehicle is set in session first
if ($('#see-on-vehicle-detail').length) {
  var car = $('#see-on-vehicle-detail');
  var year = car.data('year');
  var make = car.data('make');
  var model = car.data('model');
  var submodel = car.data('submodel');
  var wheel = car.attr('data-wheel');
  var configid = car.data('config');
  var option = car.data('option');
  if (typeof option === 'undefined') {
    option = '';
  }
  detailCarImage(car, year, make, model, submodel, wheel, 0, '', configid, option);
  setDropdownHandlers(car, year, make, model, submodel, configid, option);
}


/* ==========================================================================
     4.1  Home Page
     ========================================================================== */
/* MOTION UI - FADE IN TRANSITIONS /////////*/
if (Foundation.MediaQuery.atLeast('medium')) {
  $(window).scroll(function checkScroll() {
    $('.animate').each(function animateItem() {
      var $this = $(this);
      if (($this.offset().top - $(window).scrollTop()) - wh <= -350) {
        MotionUI.animateIn($this, 'fade-in', function adjustOpacity() {
          $this.css('opacity', '1');
        });
        $this.removeClass('animate');
      }
    });
  });
}


/* MOTION UI - RANDOM TRANSITIONS /////////*/
/*
if (Foundation.MediaQuery.atLeast('medium')) {
  $(window).scroll(function scrollPage() {
    $('.animate').each(function animateElem() {
      var $this = $(this);
      var transitions = [
        'slide-in-left',
        'slide-in-up',
        'slide-in-right',
        'fade-in',
        'hinge-in-from-right',
        'hinge-in-from-bottom',
        'hinge-in-from-left',
      ];
      var randomNum = Math.floor(Math.random() * (transitions.length - 1));
      if ($this.offset().top - $(window).scrollTop() - wh <= 0) {
        MotionUI.animateIn($this, transitions[randomNum]);
        $this.removeClass('animate');
      }
    });
  });
}*/
$('.finishes-filter .list').magnificPopup({
  delegate:'a',
  type:'image',
  mainClass: 'finishes-popup',
  image: {
  markup: '<div class="mfp-figure">'+
            '<div class="mfp-close"></div>'+
            '<div class="mfp-img"></div>'+
            '<div class="mfp-bottom-bar">'+
              '<div class="mfp-title"></div>'+
              '<div class="mfp-counter"></div>'+
            '</div>'+
          '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

  cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

  titleSrc: 'data-description', // Attribute of the target element that contains caption for the slide.
  // Or the function that should return the title. For example:
  // titleSrc: function(item) {
  //   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
  // }

  verticalFit: true, // Fits image in area vertically

  tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
},
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1],
    dots: false,
  },
})

$('.finishes-filter select').change(function(){
  
  // variables
  var category = $('select[name="category"]').children("option:selected").val();
  var type = $('select[name="type"]').children("option:selected").val();
  var color = $('select[name="color"]').children("option:selected").val();  
  
  // check if category changed
  var selectID = $(this).attr('id');
  if(selectID == 'finishes-category') {
    type = '';
    color = '';
  }
  
  // ajax search
  $.get('/ajax/getFilteredFinishFilter.cfm?category=' +category+ '&type=' +type+ '&color=' +color, function getStates(data) {
      $('#finishes #finish-search').html(data).prop('disabled', false);
  });
  $.get('/ajax/getFilteredFinishList.cfm?category=' +category+ '&type=' +type+ '&color=' +color, function getStates(data) {
      $('#finishes .list').html(data).prop('disabled', false);
  });

});









/*
$('.finishes-filter select').change(function(){
  var filterFor = $(this).children("option:selected").val();
  var filterBy = $(this).attr("name");
  
  console.log(filterFor);
  console.log(filterBy);
  checkList(filterFor,filterBy);
});


function checkList(filterFor,filterBy) {
  $('.finishes-filter .list .row > div').each(function(){
    let $listItem = $(this);
    let $listItemSKU = $listItem.data('"'+filterBy+'"');
    let $listItemDataset = $(this).context.dataset;
    let $listItemValue = $listItemDataset[filterBy];
    //let $datasetpoi = document.querySelector('#finishes-'+filterBy);
    console.log($listItem);
    console.log(filterBy);
    console.log($listItemSKU);
    //console.log($datasetpoi);
    console.log($listItemDataset);
    
    if (filterBy in $listItemDataset) {
      console.log($listItemDataset[filterBy]);
    }
    
    
    if ( $listItemValue != filterFor ) {
      $listItem.hide();
    } else {
      $listItem.show();
    }
  });
  //hideLoading();
}

*/